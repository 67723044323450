import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

import PayerProjectsList from './PayerProjectsList'

export const config: ModuleConfig = {
  title: 'Payer Projects',
  description: 'Import Payer Historical Data',
  iconColor: Color.PAYER_PROJECTS,
  name: 'payer_projects',
  to: '/payer-projects',
  exact: true,
  iconId: 'payer-1-white',
  activeColor: Color.PAYER_PROJECTS,
  Component: PayerProjectsList,
}
